<template>
  <ClubTemplate 
    :clubId="clubId"
    add="Добавить акцию"
    @clickAdd="clickAddHandler"
  >
    <div class="row row--fix">
      <template v-for="(item, index) in promotionList">
        <div class="col-4 mb-sm" :key="index">
          <CardEvent
            :img="item.img"
            :date="item.date"
            :name="item.name"
            :text="item.text"
            @remove="deletePromotion(item)"
            @edit="editPromotion(item)"
          />
        </div>
      </template>
    </div>
    <b-pagination
      v-if="pageCount > pageLimit"
      v-model="pageCurrent"
      :total-rows="pageCount"
      :per-page="pageLimit"
      @change="paginationChange"
    
      aria-controls="table"
      align="right"
      last-class="hidden"
      first-class="hidden"
      first-number
      last-number
    ></b-pagination>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import CardEvent from "@component/Module/CardEvent";

export default {
  components: {
    ClubTemplate,
    CardEvent,
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      pageCurrent: 1,
      pageLimit: 12,
      pageCount: null,
      promotionList: [],
    };
  },
  methods: {
    load(){
      RequestManager.Club.getClubPromotionsList({
        clubId  : this.clubId,
        limit   : this.pageLimit,
        page    : this.pageCurrent,
      }).then( (itemList) => {
        this.pageCount = itemList.count;
        this.promotionList = itemList.results.map((item) => {
          return {
            id        : item.id,
            img       : item.photo,
            date      : DateTime(item.published_at).format('DD.MM.YYYY'),
            name      : item.name,
            text      : item.about,
          };
        });
      });
    },
    clickAddHandler() {
      this.$router.push({ name: this.$routeName.CLUB_PROMOTION_ADD, params: { clubId: this.clubId }});
    },
    deletePromotion(promotion) {
      const options = {title: 'Удалить ?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить акцию?', options)
        .then((res) => {
          if(res && res.ok) {
            RequestManager.Club.deleteClubPromotion({
              clubId        : this.clubId,
              promotionId   : promotion.id
            }).then(() => {
              this.$toasts.push({
                message: 'Акция успешно удалена',
                duration: 4000,
              });
              this.load();
            }, () => {});
          }
        });
    },
    editPromotion(promotion) {
      this.$router.push({ name: this.$routeName.CLUB_PROMOTION_EDIT, params: { clubId: this.clubId, promotionId: promotion.id }});
    },
    paginationChange(val) {
      this.pageCurrent = val;
      this.load();
    }
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>

</style>


<template>
  <div class="card-event">
    <div class="card-event__img">
      <template v-if="img">
        <img :src="img" alt="photo">
      </template>
      <template v-else>
        <svg width="70" height="57" viewBox="0 0 70 57" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'card-event__ava'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.907 2.48a2.25 2.25 0 011.636-.705h16.354a2.25 2.25 0 011.663.735l5.852 6.423a3.75 3.75 0 002.773 1.225h12.534a2.25 2.25 0 012.25 2.25v40.41a2.25 2.25 0 01-2.25 2.25H4.28a2.25 2.25 0 01-2.25-2.25v-40.41a2.25 2.25 0 012.25-2.25h11.755a3.75 3.75 0 002.725-1.175l6.146-6.503zM26.543.275a3.75 3.75 0 00-2.726 1.174l-6.146 6.504a2.25 2.25 0 01-1.635.704H4.28a3.75 3.75 0 00-3.75 3.75v40.41a3.75 3.75 0 003.75 3.75H65.72a3.75 3.75 0 003.75-3.75v-40.41a3.75 3.75 0 00-3.75-3.75H53.185a2.25 2.25 0 01-1.664-.734L45.67 1.499A3.75 3.75 0 0042.897.275H26.543zm.63 29.542a7.548 7.548 0 1115.096 0 7.548 7.548 0 01-15.096 0zm7.548-9.047a9.048 9.048 0 100 18.095 9.048 9.048 0 000-18.095z" fill="#DEE4ED"/></svg>
      </template>
    </div>
    <div class="card-event__content">
      <div v-if="date" class="card-event__date">{{ date }}</div>
      <div class="card-event__name">{{ name }}</div>
      <div class="card-event__text">{{ text }}</div>
      <div class="card-event__more" @click="showModal">Подробнее…</div>
    </div>
    <div class="card-event__control">
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.05 11.93" v-bind:svg-inline="''" v-bind:class="'card-event__icon'" @click="$emit('edit')" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.3 11.93H7.05a.75.75 0 010-1.5h4.25a.75.75 0 010 1.5zM1.26 11.93a.75.75 0 01-.73-.58L0 9.28a1.74 1.74 0 01.37-1.47L5.9.76a2 2 0 013-.23l.93.73a2 2 0 01.49 2.93l-5.57 7.05a1.73 1.73 0 01-1.35.67H1.27zM7.08 1.68l-5.53 7a.24.24 0 000 .2l.35 1.49h1.53a.24.24 0 00.19-.09l5.47-7.02c.28-.36-.07-.7-.23-.83l-.93-.73c-.13-.1-.57-.4-.86 0z"/><path d="M8.65 5.8a.75.75 0 01-.46-.16L5 3.13A.75.75 0 015.91 2l3.2 2.51a.75.75 0 01-.46 1.29z"/></svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" v-bind:class="'card-event__icon'" @click="$emit('remove')" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
    </div>
    <b-modal ref="modal" size="lg" centered hide-footer hide-header>
      <div class="modal-event">
        <div class="modal-event__control">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15.5 15.5" v-bind:svg-inline="''" v-bind:class="'modal-event__icon'" @click="closeModal" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#555"><path d="M.76 15.49a.75.75 0 01-.53-1.28l14-14a.75.75 0 011.06 1.06l-14 14a.75.75 0 01-.53.22z"/><path d="M14.75 15.5a.75.75 0 01-.53-.22l-14-14A.75.75 0 011.28.22l14 14a.75.75 0 01-.53 1.28z"/></g></svg>
        </div>
        <div class="modal-event__body">
          <div class="modal-event__img">
            <template v-if="img">
              <img :src="img" alt="photo">
            </template>
            <template v-else>
              <svg width="70" height="57" viewBox="0 0 70 57" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'card-event__ava'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.907 2.48a2.25 2.25 0 011.636-.705h16.354a2.25 2.25 0 011.663.735l5.852 6.423a3.75 3.75 0 002.773 1.225h12.534a2.25 2.25 0 012.25 2.25v40.41a2.25 2.25 0 01-2.25 2.25H4.28a2.25 2.25 0 01-2.25-2.25v-40.41a2.25 2.25 0 012.25-2.25h11.755a3.75 3.75 0 002.725-1.175l6.146-6.503zM26.543.275a3.75 3.75 0 00-2.726 1.174l-6.146 6.504a2.25 2.25 0 01-1.635.704H4.28a3.75 3.75 0 00-3.75 3.75v40.41a3.75 3.75 0 003.75 3.75H65.72a3.75 3.75 0 003.75-3.75v-40.41a3.75 3.75 0 00-3.75-3.75H53.185a2.25 2.25 0 01-1.664-.734L45.67 1.499A3.75 3.75 0 0042.897.275H26.543zm.63 29.542a7.548 7.548 0 1115.096 0 7.548 7.548 0 01-15.096 0zm7.548-9.047a9.048 9.048 0 100 18.095 9.048 9.048 0 000-18.095z" fill="#DEE4ED"/></svg>
            </template>
          </div>
          <div class="modal-event__content">
            <div v-if="date" class="modal-event__date">{{ date }}</div>
            <div class="modal-event__name">{{ name }}</div>
            <div class="modal-event__text">{{ text }}</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "CardTrainer",
  props: {
    img: String,
    date: String,
    name: String,
    text: String,
  },
  methods: {
    showModal() {
      this.$refs['modal'].show();
    },
    closeModal() {
      this.$refs['modal'].hide();
    }
  }
};
</script>

<style lang="scss" scoped>

// card-event
.card-event {
  position: relative;
  display: flex;
  height: 100%;
  background-color: var(--br-block-prime);
  border-radius: 8px;
  .card-event__img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 112px;
    height: 112px;
    border-radius: 8px 0 0 8px;
    background-color: var(--br-block-sub);
    overflow: hidden;
    .card-event__ava {
      width: 40px;
      height: auto;
      path {
        fill: var(--text-descr);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-event__content {
    padding: 10px 16px;
    .card-event__date {
      margin-bottom: 2px;
      color: var(--text-descr);
      font-size: 9px;
      font-weight: 500;
    }
    .card-event__name {
      margin-bottom: 4px;
      color: var(--text-prime);
      font-size: 17px;
      max-height: 40px;
      overflow: hidden;
    }
    .card-event__text {
      margin-bottom: 4px;
      color: var(--text-sub);
      font-size: 14px;
      max-height: 48px;
      overflow: hidden;
    }
    .card-event__more {
      color: var(--color-prime);
      font-size: 14px;
      transition: all .2s ease-in-out;
      cursor: pointer;
      &:hover {
        color: var(--color-prime-active);
      }
    }
  }
  .card-event__control {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    .card-event__icon {
      margin-right: 5px;
      cursor: pointer;
      path {
        fill: var(--color-prime);
        transition: all .2s ease-in-out;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        path {
          fill: var(--color-sub);
        }
      }
    }
  }
  &:hover {
    .card-event__control {
      opacity: 1;
      visibility: visible;
    }
  }
}

// modal-event
.modal-event {
  position: relative;
  .modal-event__body {
    display: flex;
    .modal-event__img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 200px;
      height: 200px;
      border-radius: 8px;
      background-color: var(--br-block-sub);
      overflow: hidden;
      .card-event__ava {
        width: 40px;
        height: auto;
        path {
          fill: var(--text-descr);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .modal-event__content {
      padding: 10px 16px;
      .modal-event__date {
        margin-bottom: 6px;
        color: var(--text-descr);
        font-size: 14px;
        font-weight: 500;
      }
      .modal-event__name {
        margin-bottom: 20px;
        color: var(--text-prime);
        font-weight: 500;
        font-size: 22px;
      }
      .modal-event__text {
        color: var(--text-sub);
        font-size: 17px;
      }
    }
  }
  .modal-event__control {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    .modal-event__icon {
      cursor: pointer;
      path {
        transition: all .2s ease-in-out;
      }
      &:hover {
        path {
          fill: var(--color-prime);
        }
      }
    }
  }
}

</style>
